export const volumeByQueue = [
    {
        name: 'Auto',
        value: 894
    },
    {
        name: 'Woon',
        value: 521
    },
    {
        name: 'Reis',
        value: 721
    },
    {
        name: 'Hypotheken',
        value: 231
    },
    {
        name: 'Zorg',
        value: 981
    }, {
        name: 'Bank',
        value: 143
    }
];

