

<div class="header-label">Relatiegegevens</div>

<div class="contact-container">
  <div class="contact-item">
    <label>Naam:</label>
    <span class="editable-field">{{ name }} <i class="edit-icon">✎</i></span>
  </div>
  <div class="contact-item">
    <label>Adres:</label>
    <span class="editable-field">{{ address }} <i class="edit-icon">✎</i></span>
  </div>
  <div class="contact-item">
    <label>Postcode:</label>
    <span class="editable-field">{{ postalCode }} <i class="edit-icon">✎</i></span>
  </div>
  <div class="contact-item">
    <label>Woonplaats:</label>
    <span class="editable-field">{{ city }} <i class="edit-icon">✎</i></span>
  </div>
  <div class="contact-item">
    <label>Telefoonnummer:</label>
    <span class="editable-field">{{ phoneNumber }} <i class="edit-icon">✎</i></span>
  </div>
  <div class="contact-item">
    <label>Mobielnummer:</label>
    <span class="editable-field">{{ mobileNumber }} <i class="edit-icon">✎</i></span>
  </div>
  <div class="contact-item">
    <label>Emailadres:</label>
    <span class="editable-field">{{ email }} <i class="edit-icon">✎</i></span>
  </div>
</div>

