import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';


interface product {
    merk: string;
    product: string;
    date: string;
    text: string;
    polis: string;
}

const PRODUCT_DATA: product[] = [
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        product: 'sailing',
        date: '01-10-2020', 
        text: 'Bootverzekering (BSN,DIN,VAS,VBO,VSO)',
        polis: '300291317'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/fbto.png" width="25" height="25">', 
        product: 'rv_hookup<',
        date: '12-04-2023', 
        text: 'Aanhangwagenverzekering (AWAW01)',
        polis: '300230456'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        product: 'directions_car',
        date: '19-02-2023', 
        text: 'Autoverzekering R404VB - BMW i4 e40 (VSI,WAM,WPL)',
        polis: '300143717'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        product: 'flight',
        date: '24-10-2022', 
        text: 'Doorlopende Reisverzekering (POR)',
        polis: '300218181'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        product: 'gavel',
        date: '12-02-2022', 
        text: 'Rechtsbijstandsverzekering (SOW,VRK)',
        polis: '300194611'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        product: 'pedal_bike',
        date: '01-09-2021', 
        text: 'Fietsverzekering (ALL)',
        polis: '300194611'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/fbto.png" width="25" height="25">', 
        product: 'finance',
        date: '01-05-2021', 
        text: 'Beleggingsverzekering',
        polis: '300175334'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/fbto.png" width="25" height="25">', 
        product: 'home',
        date: '21-06-2009', 
        text: 'Inboedelverzekering (INS)',
        polis: '300050231'
    }
]



/**
 * @title Table with sorting
 */
@Component({
    selector: 'ktd-table-products',
    styleUrls: ['table-products.component.scss'],
    templateUrl: 'table-products.component.html',
})
export class KtdTableProductsComponent implements AfterViewInit {
    displayedColumns: string[] = ['merk', 'product', 'date', 'text', 'polis'];
    dataSource = new MatTableDataSource(PRODUCT_DATA);

    @ViewChild(MatSort) sort: MatSort;

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }
}

