
<label>Contactmomenten</label>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
   
    <!-- Icon Column -->
    <ng-container matColumnDef="merk">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>M</th>
        <td id="logo-cell" mat-cell *matCellDef="let element"> 
        <mat-icon [innerHTML]="element.merk"></mat-icon> </td>
    </ng-container>

    <!-- Icon Column -->
    <ng-container matColumnDef="kanaal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>K</th>
        <td id="kanaal-cell" mat-cell *matCellDef="let element"> 
        <mat-icon class="material-symbols-outlined" [innerHTML]="element.kanaal"></mat-icon> </td>
    </ng-container>

     <!-- Date Column -->
     <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
        <td id="date-cell" mat-cell *matCellDef="let element"> {{element.date}} </td>
    </ng-container>

     <!-- Text Column -->
     <ng-container matColumnDef="text">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Contactmoment</th>
        <td id="text-cell" mat-cell *matCellDef="let element"> {{element.text}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>


