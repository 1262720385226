
<label>Producten</label>

<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
   
    <!-- Icon Column -->
    <ng-container matColumnDef="merk">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>M</th>
        <td id="logo-cell" mat-cell *matCellDef="let element"> 
        <mat-icon [innerHTML]="element.merk"></mat-icon> </td>
    </ng-container>

    <!-- Icon Column -->
    <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>P</th>
        <td id="product-cell" mat-cell *matCellDef="let element"> 
        <mat-icon class="material-symbols-outlined" [innerHTML]="element.product"></mat-icon> </td>
    </ng-container>

     <!-- Date Column -->
     <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
        <td id="date-cell" mat-cell *matCellDef="let element"> {{element.date}} </td>
    </ng-container>

     <!-- Text Column -->
     <ng-container matColumnDef="text">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
        <td id="text-cell" mat-cell *matCellDef="let element"> {{element.text}} </td>
    </ng-container>

    <!-- Text Column -->
    <ng-container matColumnDef="polis">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Polisnr.</th>
        <td id="polisnr-cell" mat-cell *matCellDef="let element"> {{element.polis}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
