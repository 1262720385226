import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KtdRealLifeExampleComponent } from './real-life-example.component';
import { RouterModule, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { KtdGridModule } from '@katoid/angular-grid-layout';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { KtdTableContactsComponent } from './table-contacts/table-contacts.component';
import { KtdTableClaimsComponent } from './table-claims/table-claims.component';
import { KtdTableProductsComponent} from './table-products/table-products.component';
import { MatSortModule } from '@angular/material/sort';
import { KtdFooterComponent } from '../components/footer/footer.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { NbaDetailsComponent } from './nba-details/nba-details.component';


const routes: Routes = [
    {
        path: 'real-life-example',
        component: KtdRealLifeExampleComponent,
        data: {title: '1Medewerkerdesktop'}
    },
];

@NgModule({
    declarations: [
        KtdRealLifeExampleComponent,
        KtdTableContactsComponent,
        KtdTableClaimsComponent,
        KtdTableProductsComponent,
        ContactDetailsComponent,
        NbaDetailsComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatIconModule,
        KtdGridModule,
        NgxChartsModule,
        MatCardModule,
        MatTableModule,
        MatSortModule,
        KtdFooterComponent
    ]
})
export class KtdRealLifeExampleModule {}
