<div class="grid-container">
   
    <ktd-grid [cols]="cols"
              [rowHeight]="rowHeight"
              [preventCollision]="preventCollision"
              [layout]="layout"
              [scrollableParent]="document"
              [compactType]="compactType"
              (layoutUpdated)="onLayoutUpdated($event)"
              (gridItemResize)="onGridItemResize($event)">
              


        <ktd-grid-item id="0">
            <app-contact-details 
                name="H.J. van Lansbergen"
                address="Tweede Atjehstraat 40-1"
                postalCode="1094 LH"
                city="Amsterdam"
                phoneNumber="020-3234828"
                mobileNumber="06-22043673"
                email="h.lansbergen@gmail.com">
            </app-contact-details>
        </ktd-grid-item>


        <ktd-grid-item id="1">
            <app-nba-details >
            </app-nba-details>
        </ktd-grid-item>
    
        
        <ktd-grid-item class="ktd-grid-item-table" id="2">
            <ktd-table-products></ktd-table-products>
                <ng-template ktdGridItemPlaceholder>
                <div class="custom-placeholder-container ktd-grid-item-table">
                    <ktd-table-products></ktd-table-products>
                </div>
            </ng-template>
        </ktd-grid-item>


        <ktd-grid-item class="ktd-grid-item-table" id="3">
            <ktd-table-contacts></ktd-table-contacts>
            <ng-template ktdGridItemPlaceholder>
                <div class="custom-placeholder-container ktd-grid-item-table">
                    <ktd-table-contacts></ktd-table-contacts>
                </div>
            </ng-template>
        </ktd-grid-item>


        <ktd-grid-item class="ktd-grid-item-table" id="4">
            <ktd-table-claims></ktd-table-claims>
            <ng-template ktdGridItemPlaceholder>
                <div class="custom-placeholder-container ktd-grid-item-table">
                    <ktd-table-claims></ktd-table-claims>
                </div>
            </ng-template>
        </ktd-grid-item>


        <ktd-grid-item id="5">
            <div class="header-label">Interacties</div>

            <ngx-charts-pie-chart
                [view]="layoutSizes['5']"
                [scheme]="colorScheme"
                [results]="volumeByQueue"
                [gradient]="false"
                [legend]="false"
                [labels]="showLabels"
                [doughnut]="false"
                (select)="onSelect('5', $event)"
                (activate)="onActivate('5', $event)"
                (deactivate)="onDeactivate('5', $event)">
            </ngx-charts-pie-chart>
            <ng-template ktdGridItemPlaceholder>
                <div class="custom-placeholder-container">
                    <ngx-charts-pie-chart
                        [view]="layoutSizes['5']"
                        [scheme]="colorScheme"
                        [results]="volumeByQueue"
                        [gradient]="false"
                        [legend]="false"
                        [labels]="showLabels"
                        [animations]="false"
                        [doughnut]="false">
                    </ngx-charts-pie-chart>
                </div>
            </ng-template>
        </ktd-grid-item>


    </ktd-grid>
</div>
<ktd-footer></ktd-footer>

