
<div class="header-label">Next Best Action</div>

<div class="nba-container">
    <div class="nba-item">
      <label>CrossSell kansen:</label>  
      <span class="material-symbols-outlined">personal_injury</span>
      <span class="material-symbols-outlined">savings</span>
      <span class="material-symbols-outlined">solar_power</span>
    </div>

    <div class="nba-item">
      <label>Akties:</label>     
    </div>

    <div align="left">
        <i class="inline-icon material-symbols-outlined">looks_one</i><span class="nba-field"> Vraag de klant naar ontbrekende schadedocumenten</span>
    </div>
    <div align="left">
        <i class="inline-icon material-symbols-outlined">looks_two</i><span class="nba-field"> Vraag de klant naar update van email adres</span>
    </div>

    <br>

    <div class="nba-item">
        <label>Bijzonderheden:</label>     
      </div>

      <div align="left">
        <i class="inline-icon material-symbols-outlined">notifications</i><span class="nba-field"> Deze klant heeft een openstaande claim</span>
    </div>
    <div align="left">
        <i class="inline-icon material-symbols-outlined">mail</i><span class="nba-field"> Er is 28 oktober een brief naar deze klant gestuurd.</span>
    </div>

  </div>
  