import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';


interface ContactMoment {
    merk: string;
    kanaal: string;
    date: string;
    text: string;
}

const CONTACT_DATA: ContactMoment[] = [
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'mail',
        date: '28-10-2023', 
        text: 'Brief verstuurd m.b.t. Schademelding'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'phone_in_talk',
        date: '26-10-2023', 
        text: 'Contact aangenomen'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/fbto.png" width="25" height="25">', 
        kanaal: 'language',
        date: '24-10-2023', 
        text: 'Ingelogd op het klantdomein'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'chat',
        date: '24-02-2023',
        text: 'Contact aangenomen'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'email',
        date: '23-02-2023', 
        text: 'Contact aangenomen'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/fbto.png" width="25" height="25">', 
        kanaal: 'phone_in_talk',
        date: '18-10-2023', 
        text: 'Outbound Contact'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'chat',
        date: '02-05-2023', 
        text: 'Contact aangenomen'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'install_mobile',
        date: '01-05-2023', 
        text: 'App geregistreerd'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'language',
        date: '19-02-2023', 
        text: 'Ingelogd op het klantdomein'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'phone_in_talk',
        date: '22-12-2022', 
        text: 'Contact aangenomen'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'phone_in_talk',
        date: '17-11-2022', 
        text: 'Contact aangenomen'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'language',
        date: '19-10-2022', 
        text: 'Ingelogd op het klantdomein'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'phone_in_talk',
        date: '12-08-2022', 
        text: 'Outbound Contact'},
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'note_stack',
        date: '09-08-2022', 
        text: 'Informatieset Hypotheken verstuurd' 
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'videocam',
        date: '08-08-2022', 
        text: 'Adviesgesprek Hypotheken'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'language',
        date: '06-08-2022', 
        text: 'Ingelogd op het klantdomein'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        kanaal: 'language',
        date: '01-08-2022', 
        text: 'Ingelogd op het klantdomein'
    }
]

/**
 * @title Table with sorting
 */
@Component({
    selector: 'ktd-table-contacts',
    styleUrls: ['table-contacts.component.scss'],
    templateUrl: 'table-contacts.component.html',
})
export class KtdTableContactsComponent implements AfterViewInit {
    displayedColumns: string[] = ['merk', 'kanaal', 'date', 'text'];
    dataSource = new MatTableDataSource(CONTACT_DATA);

    @ViewChild(MatSort) sort: MatSort;

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }
}

