import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';


@Component({
    selector: 'app-contact-details',
    templateUrl: './contact-details.component.html',
    styleUrls: ['./contact-details.component.scss']
  })
  export class ContactDetailsComponent {
    @Input() name: string;
    @Input() address: string;
    @Input() postalCode: string;
    @Input() city: string;
    @Input() phoneNumber: string;
    @Input() mobileNumber: string;
    @Input() email: string;
  }

