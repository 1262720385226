import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';


interface claim {
    merk: string;
    product: string;
    date: string;
    text: string;
    status: string;
}

const CLAIM_DATA: claim[] = [
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        product: 'directions_car',
        date: '12-10-2023', 
        text: 'Tweezijdig Ongeval',
        status: 'pending'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/fbto.png" width="25" height="25">', 
        product: 'home<',
        date: '05-03-2023', 
        text: 'Inbraak',
        status: 'check_circle'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        product: 'directions_car',
        date: '13-01-2023', 
        text: 'Eenzijdige Ongeval',
        status: 'unpublished'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        product: 'flight',
        date: '24-10-2022', 
        text: 'Bagage verloren',
        status: 'check_circle'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        product: 'gavel',
        date: '12-02-2021', 
        text: 'Bijstand bij Arbeidsconflict',
        status: 'check_circle'
    },
    {
        merk: '<img src="https://raw.githubusercontent.com/pl0ink/images/main/cb.png" width="25" height="25">', 
        product: 'pedal_bike',
        date: '17-09-2021', 
        text: 'Fiets gestolen',
        status: 'check_circle'
    }
]



/**
 * @title Table with sorting
 */
@Component({
    selector: 'ktd-table-claims',
    styleUrls: ['table-claims.component.scss'],
    templateUrl: 'table-claims.component.html',
})
export class KtdTableClaimsComponent implements AfterViewInit {
    displayedColumns: string[] = ['merk', 'product', 'date', 'text', 'status'];
    dataSource = new MatTableDataSource(CLAIM_DATA);

    @ViewChild(MatSort) sort: MatSort;

    ngAfterViewInit() {
        this.dataSource.sort = this.sort;
    }
}

